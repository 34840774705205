@import url("https://fonts.cdnfonts.com/css/poppins");
$primary-color: #1a9c5b;
$primart-text: white;

body {
  font-size: 14px;
  // font-family: Poppins !important;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: black; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: $primary-color; /* color of the scroll thumb */
  border: 1px solid black; /* creates padding around scroll thumb */
}

.margin-app {
  min-height: 500px;
}

.signUpBtn,
.get-started-btn {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  font-weight: 500 !important;
  color: $primart-text !important;
  span {
    font-weight: 500 !important;
    color: $primart-text !important;
  }
}

.signUpBtn:disabled {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: $primart-text !important;
  span {
    font-weight: 500 !important;
    color: $primart-text !important;
  }
}

.signUpBtn:hover,
.signUpBtn:focus,
.get-started-btn:hover,
.get-started-btn:focus {
  color: $primart-text !important;
  border-color: $primary-color !important;
}

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: white !important;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  touch-action: manipulation;
  height: 60px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(
    60deg,
    #ff7f50,
    #8b0000,
    #229ed9,
    #25d366,
    #ff7f50,
    #8b0000
  );
  animation: animatedgradient 3s ease infinite alternate;
  background-size: 300% 300%;
}

.button-85:hover {
  transform: scale(0.94);
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg, #229ed9, #25d366, #ff7f50, #8b0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    60deg,
    #ff7f50,
    #8b0000,
    #229ed9,
    #25d366,
    #ff7f50,
    #8b0000
  );
  animation: glowing-button-85 20s linear infinite;
  background-size: 300% 300%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.gradient-btn {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  background: linear-gradient(45deg, #ff6f00, #ff3d00);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gradient-btn:hover {
  background: linear-gradient(45deg, #ff3d00, #ff6f00);
}

.react-video-player-custom {
  iframe {
    border-radius: 20px;
  }
}

.testimonials-parent .carousel .control-next.control-arrow:before {
  border-left: 15px solid $primary-color;
}

.testimonials-parent .carousel .control-prev.control-arrow:before {
  border-right: 15px solid $primary-color;
}

.testimonials-parent .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: initial !important;
}

.blogs-brief .ant-card-hoverable:hover {
  background: black;
  border: 2px solid $primary-color;
  .ant-card-body {
    background: black;
  }
  .ant-card-meta-title {
    color: $primary-color;
  }
  .ant-card-meta-description {
    color: $primary-color;
  }
}

.dot.selected {
  background: $primary-color !important;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.7) !important;
}

.dot.selected:focus {
  outline: 0 !important;
}

.dot {
  background: #979197 !important;
}

.blogs-brief-bg {
  background: #ededed;
  padding: 30px 0px;
}

.testimonials-brief-bg {
  padding: 30px 0px;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

.grey-bg {
  background-color: #ededed;
}

.black-bg {
  background-color: black;
}

.white-bg {
  background-color: white;
}

.padding-20 {
  padding: 20px 0;
}

.padding-30 {
  padding: 30px 0;
}

.padding-5 {
  padding: 5px 0;
}

.padding-20-all {
  padding: 20px !important;
}

.testimonial-slider .carousel .slide {
  background: transparent !important;
  color: black;
  height: 100%;
}

.blogs-slider-mobile .carousel .slide {
  background: transparent !important;
  color: black;
  height: 100%;
}

.testimonial-slider .myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.testimonial-slider .carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 0% !important;
}

.testimonial-slider-content .carousel-root {
  margin-top: 0% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.scrollTop {
  position: fixed;
  bottom: 40px;
  width: 80px;
  right: 100px;
  height: 50px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.nav-list-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials {
  height: 60px;
  width: 60px;
  background: $primary-color;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials-small {
  height: 50px;
  width: 50px;
  background: $primary-color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials-small span {
  font-size: 35px;
  font-weight: 500;
  line-height: 25px;
  vertical-align: middle;
  margin-bottom: 10px;
}

.initials span {
  font-size: 45px;
  font-weight: 500;
  line-height: 50px;
  vertical-align: middle;
  margin-bottom: 0px;
}

.initials:hover,
.initials-small:hover,
.profile-image:hover {
  cursor: pointer;
}

.initials-testimonials {
  height: 60px;
  width: 60px;
  background: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.initials-testimonials span {
  font-size: 45px;
  font-weight: 500;
  line-height: 50px;
  vertical-align: middle;
  margin-bottom: 0px;
}
